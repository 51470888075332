interface SortingModel {
  field: string;
  sort_by: {
    asc: string;
    desc: string;
  };
}

const sortingModel: SortingModel[] = [
  {
    field: 'name',
    sort_by: {
      asc: 'first_name',
      desc: '-first_name',
    },
  },
  {
    field: 'email',
    sort_by: {
      asc: 'email',
      desc: '-email',
    },
  },
  {
    field: 'invited_by',
    sort_by: {
      asc: 'invited_by',
      desc: '-invited_by',
    },
  },
];

export default sortingModel;
